<template>
  <b-modal
    visible
    centered
    title="Удаление истории"
    hide-footer
    @hidden="onClose"
  >
    <div
      class="crm-container story-container"
    >
      <div class="description">
        Вы уверены, что хотите удалить историю?
      </div>
      <div class="crm-wrapper-buttons">
        <b-button
          variant="outline-primary"
          :type="$const.PRIMARY_BUTTON"
          class="mr-5"
          @click="onClose"
        >
          Отменить
        </b-button>

        <b-button
          variant="danger"
          :type="$const.PRIMARY_BUTTON"
          class="px-3"
          :loading="isDeleting"
          @click="onClickDelete"
        >
          Удалить
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';

export default {
  name: 'StoryDeleteModal',
  components: {
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    storyId: {
      type: String,
      default: null,
    },
    // afterDelete: {
    //   type: [Function],
    //   default: () => {},
    // },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        this.$emit('delete', this.storyId);
        this.onClose();
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
        this.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.story-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
